tr > th, td {
    border-bottom-color: lightgray !important;
}

input[type=checkbox] {
    margin-right: 8px;
    vertical-align: middle;

    + label {
        vertical-align: middle;
    }

    &:checked + label.strikethrough {
        text-decoration: line-through;
    }
}
