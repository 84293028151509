.ds-container>ul.ant-menu-sub {
  max-height: 60vh;
  overflow: auto;
  scrollbar-width: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 2px;
}