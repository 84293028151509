.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 100px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.col-gender {
  background-color: rgba(255, 51, 0, 0.3);
}

.ant-table-tbody>tr>td.col-gender.ant-table-cell-row-hover {
  background-color: rgba(255, 51, 0, 0.2);
}

.col-ethnicity {
  background-color: rgba(112, 48, 160, 0.3);
}

.ant-table-tbody>tr>td.col-ethnicity.ant-table-cell-row-hover {
  background-color: rgba(112, 48, 160, 0.2);
}

.col-disability {
  background-color: rgba(255, 255, 0, 0.3);
}

.ant-table-tbody>tr>td.col-disability.ant-table-cell-row-hover {
  background-color: rgba(255, 255, 0, 0.2);
}

.card-container p {
  margin: 0;
}

.card-container>.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: 4px solid #1890ff;
}

.card-container>.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #f0f0f0;
  border-bottom: 4px solid #1890ff;
}

.card-container>.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  border: 4px solid #1890ff;
  border-bottom-color: #fff
}

@keyframes example {
  10% {
    background-color: red;
    transform: translateX(-100px) rotate(36deg);

  }

  20% {
    background-color: orange;
    transform: translateX(0) rotate(72deg);

  }

  30% {
    background-color: yellow;
    transform: translateX(100px) rotate(108deg);

  }

  40% {
    background-color: green;
    transform: translateX(0) rotate(144deg);

  }

  50% {
    background-color: blue;
    transform: translateX(-100px) rotate(180deg);

  }

  60% {
    background-color: indigo;
    transform: translateX(0) rotate(216deg);

  }

  70% {
    background-color: violet;
    transform: translateX(100px) rotate(252deg);

  }

  80% {
    background-color: darkviolet;
    transform: translateX(0) rotate(288deg);

  }

  90% {
    background-color: black;
    transform: translateX(-100px) rotate(324deg);

  }

  100% {
    background-color: white;
    transform: translateX(0) rotate(360deg);

  }

}

.unbalancedRow2 {
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes balancing {
  0% {
    transform: rotate(0)
  }

  5% {
    transform: rotate(1deg)
  }

  10% {
    transform: rotate(2deg)
  }

  15% {
    transform: rotate(3deg)
  }

  20% {
    transform: rotate(4deg)
  }

  25% {
    transform: rotate(5deg)
  }

  30% {
    transform: rotate(4deg)
  }

  35% {
    transform: rotate(3deg)
  }

  40% {
    transform: rotate(2deg)
  }

  45% {
    transform: rotate(1deg)
  }

  50% {
    transform: rotate(0)
  }

  55% {
    transform: rotate(-1deg)
  }

  60% {
    transform: rotate(-2deg)
  }

  65% {
    transform: rotate(-3deg)
  }

  70% {
    transform: rotate(-4deg)
  }

  75% {
    transform: rotate(-5deg)
  }

  80% {
    transform: rotate(-4deg)
  }

  85% {
    transform: rotate(-3deg)
  }

  90% {
    transform: rotate(-2deg)
  }

  95% {
    transform: rotate(-1deg)
  }
}

.unbalancedRow {
  background-image: linear-gradient(to right, rgba(50, 50, 50, 0.1), rgba(50, 50, 50, 0.1));
}

.unbalancedRow td {
  color: red;
  font-weight: 700;
}