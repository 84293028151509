.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  max-width: 600px;
  flex-basis: 600px;
}
