.pop-confirm-content {
    width: 50vw;
}

.input-card {
    margin-bottom: 16px;
}

.input-card-buttons {
    padding-top: 8px;
}

.input-card-spacing {
    padding-top: 16px;
}

.required-input::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}