@import "~antd/dist/reset.css";

.header {
  background: #fff;
}

.header__logo {
  float: left;
  height: 50px;
}

.header__logo img {
  max-height: 100%;
  object-fit: cover;
  align-content: baseline;
}

.header__user-profile-item {
  display: inline-block;
  padding-left: 10px;
}

.ant-layout-header {
  padding: 0 20px !important;
}