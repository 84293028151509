a .navigation-card {
    align-items: center;
    background: #fafafa;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    box-shadow: 0.2em 0.3em 0.75em rgba(0, 0, 50, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
}

a:hover .navigation-card {
    background: #fdfdfd;
    border: 1px solid blue;
}

a:active .navigation-card {
    border: 1px solid blue;
}